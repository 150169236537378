<!--
 * @Description: 运营位管理--焦点图表单页面
 * @Author: 小广
 * @Date: 2019-06-18 08:52:35
 * @LastEditors: 小广
 * @LastEditTime: 2020-05-12 19:15:23
 -->
<template>
  <div class="focusManageForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :footerShow="footerShow"
      @update="update"
    >
      <col2-detail>
        <div v-if="isAdd" class="title-wrapper">
          <span>注：焦点图最多显示8幅，排序规则以设置的排序从小到大排序，如排序一致，则最新添加的排前面。</span>
        </div>
        <col2-block :title="isAdd || !footerShow ? '' : '焦点图信息'">
          <div v-if="footerShow">
            <el-form-item
              label="标题"
              prop="title"
              :rules="[
                { required: true, message: '标题不允许为空', trigger: 'blur' }
              ]"
            >
              <v-input
                v-model="form.title"
                placeholder="标题字数最多30字"
                :maxlength="30"
                :width="width"
              ></v-input>
            </el-form-item>
          </div>
          <el-form-item v-else label="标题">
            <span>{{ form.title }}</span>
          </el-form-item>
          <div v-if="footerShow">
            <el-form-item
              label="焦点图"
              prop="smallPic"
              :rules="[
                { required: true, message: '请上传图片', trigger: 'change' }
              ]"
            >
              <v-uploader
                :action="uploadURL"
                :imgUrls.sync="form.smallPic"
                :fileHeight="294"
                :fileWidth="690"
                :imageWH="[690, 294]"
                :fileSize="0.5"
              >
                <template #tip>
                  <span>注：仅支持尺寸为690*294px，小于512KB的图片上传</span>
                </template>
              </v-uploader>
            </el-form-item>
          </div>
          <el-form-item v-else label="焦点图">
            <div>
              <v-preview :imgs="smallPicUrls"></v-preview>
            </div>
          </el-form-item>
          <el-form-item v-if="!isAdd" label="发布项目">
            <span>{{ detailItem.communityName }}</span>
          </el-form-item>
          <el-form-item required v-if="footerShow" label="跳转类型">
            <checkbox-plus
              :options="focusTypeOps"
              :value.sync="form.focusType"
            ></checkbox-plus>
          </el-form-item>
          <el-form-item v-else label="跳转类型">
            <span>{{ focusTypeMap[form.focusType] }}</span>
          </el-form-item>
          <div class="el-form-div-wrapper" v-if="form.focusType === 1">
            <el-form-item
              label="内容"
              prop="content"
              :rules="[
                {
                  required: true,
                  message: '输入内容不能为空',
                  trigger: 'change'
                }
              ]"
            >
              <v-ueditor v-model="form.content" ref="ueditor"></v-ueditor>
              <div class="choose-jump-wrapper" v-if="footerShow">
                <jump-go @callback="jumpCallback"></jump-go>
              </div>
            </el-form-item>
          </div>
          <div v-if="form.focusType === 2 && footerShow">
            <el-form-item label="跳转页面">
              <jump-go
                :btnText="jumpName"
                :skipType="2"
                @callback="jumpFunCallback"
              ></jump-go>
            </el-form-item>
          </div>
          <el-form-item
            v-if="form.focusType === 2 && !footerShow"
            label="跳转页面"
          >
            <span>{{ jumpName }}</span>
          </el-form-item>
          <div v-if="footerShow">
            <el-form-item
              v-if="form.focusType === 3"
              label="外部链接"
              prop="url"
              :rules="urlRules"
            >
              <div class="content-wrapper">
                <v-input
                  v-model="form.url"
                  placeholder="外部链接URL地址"
                  :width="width"
                  :maxlength="200"
                ></v-input>
                <span class="right-tip"
                  >注：请填写以"http://"或"https://"开头的URL地址
                </span>
              </div>
            </el-form-item>
          </div>
          <el-form-item
            v-if="!footerShow && form.focusType === 3"
            label="外部链接"
          >
            <span>{{ form.url }}</span>
          </el-form-item>
          <el-form-item 
          v-if="footerShow" 
          label="发布位置"
          :rules="[
              {
                required: true,
                message: '当前选项不允许为空',
                trigger: 'change'
              }
            ]"
          >
            <checkbox-plus
              :options="publishTypeOps"
              :value.sync="form.type"
            ></checkbox-plus>
          </el-form-item>
          <el-form-item v-else label="发布位置">
            <span>{{ publishTypeMap[form.type] }}</span>
          </el-form-item>
          <div v-if="footerShow && form.focusType === 1">
            <el-form-item
              label="分享文案"
              prop="shareContent"
              :rules="[
                { required: true, message: '请输入分享文案', trigger: 'blur' }
              ]"
            >
              <v-textarea
                v-model="form.shareContent"
                placeholder="分享时所显示的分享文案，可输入30个字"
                :maxlength="30"
              ></v-textarea>
            </el-form-item>
          </div>
          <el-form-item
            v-if="!footerShow && form.focusType === 1"
            label="分享文案"
          >
            <span>{{ form.shareContent }}</span>
          </el-form-item>
          <div v-if="footerShow">
            <el-form-item
              label="排序"
              prop="sort"
              :rules="[
                { required: true, message: '排序不能为空', trigger: 'blur' }
              ]"
            >
              <v-input-number
                v-model="form.sort"
                placeholder="请输入排序"
                :min="0"
                :max="9999999999"
                controls
                controlsPosition="right"
                :width="width"
              />
            </el-form-item>
          </div>
          <el-form-item v-else label="排序">
            <span>{{ form.sort }}</span>
          </el-form-item>
          <div v-if="isAdd">
            <el-form-item
              label="发布项目"
              prop="communityIds"
              :rules="[
                {
                  type: 'array',
                  required: true,
                  message: '当前选项不允许为空',
                  trigger: 'change'
                }
              ]"
            >
              <chosenListPanel
                :list.sync="form.communityIds"
                @select="selectCommunityShow = true"
                @change="selectCommunityChange"
              ></chosenListPanel>
            </el-form-item>
          </div>
          <div v-if="footerShow">
            <el-form-item
              label="上线时间"
              prop="onlineFromDate"
              :rules="[
                {
                  required: true,
                  message: '上线时间不允许为空',
                  trigger: 'blur'
                }
              ]"
            >
              <div class="content-wrapper">
                <v-datepicker
                  :width="width"
                  :disabled="!footerShow"
                  :minuteStep="10"
                  :minDate="startMinDate"
                  v-model="form.onlineFromDate"
                  type="datetime"
                  format="YYYY-MM-DD HH:mm"
                />
                <span class="right-tip">注：时间间隔可设为10分钟</span>
              </div>
            </el-form-item>
            <el-form-item
              label="下线时间"
              prop="onlineToDate"
              :rules="[
                {
                  required: true,
                  message: '下线时间不允许为空',
                  trigger: 'blur'
                }
              ]"
            >
              <v-datepicker
                :width="width"
                :disabled="!footerShow"
                :minuteStep="10"
                :minDate="endMinDate"
                v-model="form.onlineToDate"
                type="datetime"
                format="YYYY-MM-DD HH:mm"
              />
            </el-form-item>
          </div>
          <el-form-item v-if="!footerShow" label="上线时间段">
            <span>{{ `${form.onlineFromDate} ~ ${form.onlineToDate}` }}</span>
          </el-form-item>
          <el-form-item v-if="status === 0 || status === 1" label="焦点图状态">
            <span>{{ statusMap[form.status] }}</span>
          </el-form-item>
        </col2-block>
        <col2-block v-if="!isAdd && status !== 0 && status !== 1" title="操作信息">
          <el-form-item
            label="焦点图状态"
            :rules="[
              { required: true, message: '请选择状态', trigger: 'change' }
            ]"
            prop="status"
          >
            <v-select
              v-model="form.status"
              :options="auditStatusOps"
              :width="width"
            />
          </el-form-item>
          <el-form-item
            v-if="form.status === 3 && !isBack"
            label="操作原因"
            prop="returnReason"
            :rules="[
              { required: true, message: '请输入操作原因', trigger: 'blur' }
            ]"
          >
            <v-textarea
              v-model="form.returnReason"
              placeholder="请输入操作原因"
            ></v-textarea>
          </el-form-item>
          <el-form-item v-if="isBack" label="被退回原因">
            <span>{{ form.returnReason }}</span>
          </el-form-item>
        </col2-block>
        <multi-select
          title="项目列表"
          :isShow.sync="selectCommunityShow"
          :searchUrl="getCommunityListURL"
          :headers="communityTableHeader"
          :searchParams="searchParams"
          :responseParams="responseParams"
          :responseKey="responseKey"
          :backFill.sync="form.communityIds"
        >
          <template #searchSlot>
            <v-input
              label="项目名称"
              v-model="searchParams.communityName"
            ></v-input>
            <v-select
              label="区域类型"
              v-model="searchParams.searchRegionType"
              :options="searchRegionTypeOps"
            ></v-select>
            <v-select2
              label="所属区域"
              v-model="searchParams.regionId"
              v-bind="regionParams"
              :subjoin="{regionType : searchParams.searchRegionType}"
            ></v-select2>
            <v-input label="所在省" v-model="searchParams.province"></v-input>
            <v-input label="所在城市" v-model="searchParams.city"></v-input>
            <v-input label="所在区" v-model="searchParams.area"></v-input>
            <v-select
              label="项目阶段状态"
              v-model="searchParams.communityStage"
              :options="communityStageOps"
            ></v-select>
            <v-select
              label="项目类型"
              v-model="searchParams.communityType"
              :options="communityTypeOps"
            ></v-select>
            <v-select
              label="项目状态"
              v-model="searchParams.communityStatus"
              :options="communityStatusOps"
            ></v-select>
          </template>
        </multi-select>
      </col2-detail>
      <template #saveAfterSlot>
        <v-button text="预览" @click="isPreviewShow = true"></v-button>
      </template>
    </form-panel>
    <mobile-preview :isShow.sync="isPreviewShow" title="内容预览">
      <div class="preview-content" slot="content">
        <div class="preview-title">{{ form.title }}</div>
        <div class="preview-time">{{ form.onlineFromDate }}</div>
        <div v-if="form.focusType === 1" v-html="form.content"></div>
      </div>
    </mobile-preview>
  </div>
</template>

<script>
import {
  createURL,
  editURL,
  queryURL,
  uploadURL,
  getCommunityListURL,
  getRegionsListURL,
  getProjectTypesURL,
  getRoomTypesURL,
  checkSkipCommunityURL
} from './api'
import {
  statusMap,
  auditStatusOps,
  focusTypeMap,
  publishTypeMap,
  searchRegionTypeOps,
  communityStatusMap,
  communityStatusOps,
  jumpGoCodeMap,
  publishTypeMapHelperMap,
  focusotherTypeMapHelperMap
} from './map'
import { vUploader, vUeditor, vPreview } from 'components/control'
import {
  Col2Detail,
  Col2Block,
  CheckboxPlus,
  MultiSelect,
  ChosenListPanel,
  JumpGo,
  MobilePreview
} from 'components/bussiness'
import moment from 'moment'
import { jumpGoCode } from 'components/bussiness/jump-go/code.js'

export default {
  components: {
    vUploader,
    Col2Detail,
    Col2Block,
    MultiSelect,
    ChosenListPanel,
    CheckboxPlus,
    vUeditor,
    vPreview,
    JumpGo,
    MobilePreview
  },
  data () {
    const urlValidator = (rule, value, callback) => {
      const urlRegular = /(http|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\\.,@?^=%&:/~\\+#]*[\w\-\\@?^=%&/~\\+#])?/
      let showError = false
      if (value && value.length) {
        showError = !urlRegular.test(value)
      }
      showError ? callback(new Error('请填写以"http://"或"https://"开头的URL地址')) : callback()
    }
    return {
      urlRules: [
        { validator: urlValidator, trigger: 'blur' }
      ],
      httpTip: '',
      width: 182,
      uploadURL: uploadURL,
      getCommunityListURL: getCommunityListURL,
      statusMap,
      auditStatusOps: auditStatusOps(),
      focusTypeMap: focusTypeMap,
      publishTypeMap: publishTypeMap,
      searchRegionTypeOps: searchRegionTypeOps(1),
      communityStatusOps: communityStatusOps(1),
      smallPicUrls: [],
      status: '',
      form: {
        id: undefined,
        title: '',
        focusType: 1,
        content: '',
        smallPic: '',
        url: '',
        type: 1,
        sort: undefined,
        shareContent: '',
        onlineFromDate: '',
        onlineToDate: '',
        status: 2,
        communityIds: [],
        sourceType: 2,
        returnReason: '',
        skipData: ''
      },
      detailItem: {},
      submitConfig: {
        queryUrl: '',
        submitUrl: '',
        submitMethod: ''
      },
      
      isAdd: false,
      footerShow: false,
      // 是否是退回
      isBack: false,
      selectCommunityShow: false,
      isPreviewShow: false,
      communityTableHeader: [
        {
          prop: 'communityName',
          label: '项目名称'
        },
        {
          prop: 'communityStatusText',
          label: '项目状态',
          formatter (row) {
            return communityStatusMap[row.communityStatus]
          }
        },
        {
          prop: 'communityStage',
          label: '项目阶段'
        },
        {
          prop: 'provinceName',
          label: '所在省'
        },
        {
          prop: 'cityName',
          label: '所在市'
        },
        {
          prop: 'countryName',
          label: '所在区'
        },
        {
          prop: 'communityPhone',
          label: '项目电话'
        }
      ],
      searchParams: {
        communityName: '',
        searchRegionType: undefined,
        regionId: '',
        province: '',
        city: '',
        area: '',
        communityStage: undefined,
        communityType: undefined,
        communityStatus: 0
      },
      regionParams: {
        searchUrl: getRegionsListURL,
        request: {
          text: 'regionName',
          value: 'regionId'
        }
      },
      responseParams: {
        id: 'id',
        name: 'communityName'
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      communityStageOps: [],
      communityTypeOps: [],
      checkParam: {},
      jumpName: '点击选择要跳转的页面',
      checkParam2: {},
    }
  },

  computed: {
    auditPermission () {
      let audit = this.$store.state.permission.audit
      let check = this.$store.state.permission.check
      return !!audit || !!check
    },

    startMinDate () {
      let minDate = moment().format('YYYY-MM-DD HH:mm')
      if (!this.isAdd && this.detailItem) {
        minDate = this.detailItem.onlineFromDate
      }
      return minDate
    },

    endMinDate () {
      return this.form.onlineFromDate
    },
    // 发布位置
    publishTypeOps () {
      let opsFun = publishTypeMapHelperMap[this.form.focusType]
      if(this.form.focusType==2){
        this.form.type = 1
      }
      // console.log("this.form.focusType",this.form.focusType,"this.form.type",this.form.type,)
      return opsFun(null, 'label')
      
    },
    // 跳转类型
    focusTypeOps(){
      let opsType = focusotherTypeMapHelperMap[this.form.type]
      return opsType(null, 'label')
    }
  },

  created () {
    const { id } = this.$route.query
    this.isAdd = !id
    // let isAdd = this.isAdd
    // if(isAdd){
    //   this.QueryUrl = ''
    // }else{
    //   this.QueryUrl = `${queryURL}/${id}`
    // }
    this.submitConfig = {
      queryUrl: this.isAdd ? '' : `${queryURL}/${id}`,
      // queryUrl: this.QueryUrl,
      submitUrl: this.isAdd ? createURL : editURL,
      submitMethod: this.isAdd ? 'post' : 'put',
      submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8'
    }
    // console.log("this.submitConfig",this.submitConfig)
    let title = this.isAdd ? '新增焦点图' : '编辑焦点图'
    // 设置title
    this.$setBreadcrumb(title)
  },

  mounted () {
    if (!this.isAdd) {
      this.$refs.formPanel.getData()
    } else {
      this.footerShow = true
      // 获取选择发布项目相关的数据
      this.getProjectTypes()
      this.getRoomTypes()
    }
  },
  methods: {
    update (data) {
      this.status = data.status
      this.footerShow = data.status !== 0
      this.isBack = data.status === 3
      if (data.smallPic) {
        this.smallPicUrls = [data.smallPic]
      }
      data.focusType = parseInt(data.focusType)
      data.type = parseInt(data.type || '1')
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })

      if (this.isBack) {
        // 当是退回状态时，已审核去掉
        this.auditStatusOps = [
          {
            text: '待审核',
            value: 2
          },
          {
            text: '已退回',
            value: 3
          }
        ]
        // 选择的园区进行回显
        this.form.communityIds = [
          {
            id: data.communityId,
            text: data.communityName
          }
        ]

        // 校验参数
        this.checkParam.communityList = [
          {
            communityId: data.communityId,
            communityName: data.communityName
          }
        ]

        // 获取选择发布项目相关的数据
        this.getProjectTypes()
        this.getRoomTypes()
      }

      if (data.skipData && data.skipData.length) {
        this.skipData = data.skipData
        let skipData = JSON.parse(data.skipData)
        this.jumpName = skipData.name || this.jumpName
      }

      // 详情赋值
      this.detailItem = data
    },

    async submitBefore (data) {
      if (this.status === 1 && !await this.$confirm('该焦点图内容已通过审核，若进行修改会影响用户查看到的内容，是否确认修改?')) {
        return
      }
      if (data.communityIds && data.communityIds.length) {
        data.communityIds = JSON.stringify(data.communityIds.map(item => item.id))
      }

      if (data.focusType === 1) {
        // 详情
        data.url = undefined
        data.skipData = undefined
      } else if (data.focusType === 2) {
        // 功能页面
        data.skipData = this.skipData
        let canSave = true
        if (data.skipData && data.skipData.length) {
          let skipData = JSON.parse(data.skipData)
          if (!skipData.name || skipData.name.length === 0) {
            canSave = false
          }
        } else {
          canSave = false
        }
        if (!canSave) {
          this.$message('请选择需要跳转的页面')
          return false
        }
        data.content = undefined
        data.url = undefined
        data.content = undefined
        data.shareContent = undefined
      } else if (data.focusType === 3) {
        // 外链
        if (data.url && data.url.length) {
          let isUrl = data.url.indexOf('https://') || data.url.indexOf('http://')
          if (!isUrl) {
            this.$message('请填写以"http://"或"https://"开头的URL地址')
            return false
          }
        } else {
          this.$message('请填写外链地址')
          return false
        }
        let skipUrl = {
          pageId: '40000',
          pageType: 4,
          url: data.url,
          H5NeedAuthorize: false,
          detailId: ''
        }
        data.skipData = JSON.stringify(skipUrl)
        data.content = undefined
        data.shareContent = undefined
      }
      if (moment(data.onlineFromDate).isSame(data.onlineToDate)) {
        this.$message('上线下线时间不能相同')
        return false
      }

      if (moment(data.onlineFromDate).isAfter(data.onlineToDate)) {
        this.$message('上线时间不能晚于下线时间')
        return false
      }

      if (this.isAdd || this.isBack) {
        // 新增和回退
        // 产品要求，在保存的时候，也进行校验
        if (this.form.focusType === 1) {
          // 这里取content里面的跳转页面数据，若是有，再进行校验
          if (this.dealWithDetailSkipData()) {
            let isOk = await this.checkSkipCommunity()
            if (!isOk) {
              return false
            }
          }
        } else if (this.form.focusType === 2) {
          if (this.skipData && this.skipData.length) {
            this.checkParam = {}
            let skipData = JSON.parse(this.skipData)
            // 获取校验参数
            this.dealWithSelectJump(skipData, this.checkParam)
            // 进行校验请求
            let isOk = await this.checkSkipCommunity()
            if (!isOk) {
              return false
            }
          }
        }
      } else {
        // 只是编辑
        data.dataObject = data.title
        if (data.status !== 3) {
          // 退回原因
          data.returnReason = undefined
        }
      }

      let userInfo = this.$store.state.userInfo
      if (userInfo && this.isAdd) {
        if (userInfo.userType === '100' && this.auditPermission) {
          // 如果权限是100，代表集团管理员
          let isOk = await this.$confirm(
            '确认发布？确认后将在发布时间发布到app?',
            { title: '提示' }
          )
          if (!isOk) {
            return false
          } else {
            // 集团管理员默认审核通过
            data.status = 1
          }
        }
      }

      let postData = {}
      Object.keys(data).forEach(item => {
        if (data[item] !== undefined) {
          postData[item] = data[item]
        }
      })

      return postData
    },

    getProjectTypes () {
      this.$axios.get(getProjectTypesURL).then(res => {
        if (res.status === '100') {
          let { data } = res
          let ops = data.map(val => {
            return {
              text: val.value,
              value: val.id
            }
          })
          this.communityStageOps = [
            {
              text: '全部',
              value: undefined
            },
            ...ops
          ]
        }
      })
    },
    getRoomTypes () {
      this.$axios.get(getRoomTypesURL).then(res => {
        if (res.status === '100') {
          let { data } = res
          let ops = data.map(val => {
            return {
              text: val.value,
              value: val.id
            }
          })
          this.communityTypeOps = [
            {
              text: '全部',
              value: undefined
            },
            ...ops
          ]
        }
      })
    },

    // 插入跳转的回调
    jumpCallback (skipdata, css, html) {
      const editorInstance = this.$refs.ueditor.$refs.ueditorWrap.editor
      editorInstance.focus()
      let content = ''
      if (this.form.content.indexOf('jumpResult') === -1) {
        content = `${css}${html}`
      } else {
        content = html
      }
      editorInstance.execCommand('inserthtml', content)
      this.form.content = editorInstance.getContent()
    },

    jumpFunCallback (skipdata, name) {
      if (!name) return
      this.jumpName = name
      this.skipData = JSON.stringify(skipdata)

      this.form.skipData = JSON.stringify(skipdata)
      let checkParam = {
        storeIdList: [],
        socialActivityIdList: [],
        secKillIdList: [],
        knowledgeInfoIdList: [],
        discountTopicId: [],
        groupIdList: [],
        productIdList: [],
        questionIdList: [],
        questionNaireIdList: [],
        selfRuleIdList: [],
        subIdList: [],
        tableIdList: [],
        workRoomIdList: []
      }
      let isAdd = false
      Object.keys(jumpGoCode).forEach(item => {

        if (skipdata.pageId === jumpGoCode[item]) {
          if (skipdata.pageType !== 3) {
            // 3是应用，使用subIdList
            isAdd = true
            checkParam[jumpGoCodeMap[item]] = [skipdata.detailId]
          }
        }
      })
      // 问卷调查特殊处理=>至详情的问卷调查才能跳转到问卷调查详情页
      if (skipdata.pageId === '40000') {
        isAdd = true
        checkParam['questionIdList'].push(skipdata.detailId)
      }
      !isAdd && (checkParam.subIdList = [skipdata.detailId])
      this.checkParam2 = checkParam
    },

    // 选择的项目有改变
    selectCommunityChange (list) {
      if (list && list.length) {
        // 在这进行校验
        if (this.form.focusType === 1) {
          //  这里取content里面的跳转页面数据，若是有，再进行校验
          if (this.dealWithDetailSkipData()) {
            this.checkSkipCommunity()
          }
        } else if (this.form.focusType === 2) {
          if (this.skipData && this.skipData.length) {
            this.checkParam = {}
            let skipData = JSON.parse(this.skipData)
            // 获取校验参数
            this.dealWithSelectJump(skipData, this.checkParam)
            // 进行校验请求
            // this.checkSkipCommunity()
          }
        }
      }
    },

    // 校验跳转内容是否覆盖项目
    async checkSkipCommunity () {
      if (this.form.focusType === 2) {
        // 功能页面 要处理下
        if (this.skipData && this.skipData.length) {
          let skipData = JSON.parse(this.skipData)
          if (skipData.pageId === '20010') {
            // 代表是详情，服务端自动生成的，不进行校验
            return true
          }
        }
      }

      let communityIds = this.form.communityIds
      let communityList = communityIds.map(item => {
        return {
          communityId: item.id,
          communityName: item.text
        }
      })
      // 处理园区的communityList
      let params = {
          communityList: communityList,
          ...this.checkParam2
        }

      let res = await this.$axios.post(checkSkipCommunityURL, params)
      if (res.status === 100) {
        if (res.data && res.data.length > 0) {
          let tempList = res.data.map(item => item.communityName)
          let message = `跳转内容无法覆盖项目【${tempList.join(
            '、'
          )}】，发布之后这些项目点击将无法实现跳转，请确认是否发布`
          let isOk = await this.$confirm(message, { title: '提示', customClass: 'message-info-body' })
          if (!isOk) {
            // 不同意，就要清空所选的园区
            this.form.communityIds = []
            return false
          }
        }
      }
      return true
    },

    // 根据value找到key
    findKey (obj, value, compare = (a, b) => a === b) {
      return Object.keys(obj).find(k => compare(obj[k], value))
    },

    // 处理至详情页的skipData校验数据
    dealWithDetailSkipData () {
      let div = document.createElement('div')
      div.innerHTML = this.form.content
      let jumpResultList = []
      let dom = div.getElementsByClassName('jumpResult')
      for (let i = 0; i < dom.length; i++) {
        jumpResultList.push({
          pageId: dom[i].attributes.pageid.nodeValue,
          detailId: dom[i].attributes.detailid.nodeValue
        })
      }
      if (jumpResultList.length) {
        this.checkParam = {}
        jumpResultList.forEach(skipData => {
          this.dealWithSelectJump(skipData, this.checkParam)
        })
        return true
      }
      return false
    },

    // 处理跳转内容参数，以便校验跳转的项目覆盖
    dealWithSelectJump (skipData, checkParam) {
      // TOOD 处理选中的参数，选择对应的类型加入checkParam
      if (skipData) {
        // 凤姣说3代表应用
        if (skipData.pageType === 3) {
          // 代表是应用
          if (!checkParam.subIdList) {
            checkParam.subIdList = []
          }
          checkParam.subIdList.push(skipData.detailId)
        } else {
          // 获取类型
          let pageId = skipData.pageId
          let codeKey = this.findKey(jumpGoCode, pageId) || ''
          // 根据codeKey 查询对应参数
          let valueId = jumpGoCodeMap[codeKey]
          if (!checkParam[valueId]) {
            checkParam[valueId] = []
          }
          if (valueId) {
            checkParam[valueId].push(skipData.detailId)
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.focusManageForm-wrapper {
  .foot-button {
    width: 168px;
    margin-right: 10px;
  }

  .title-wrapper {
    font-weight: 700;
    margin-bottom: 15px;
    margin-left: 30px;
  }

  .el-form-div-wrapper {
    width: 100%;
    display: inline-block;

    .choose-jump-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 5px;
      width: 750px;
    }
  }

  .content-wrapper {
    display: flex;
    justify-content: flex-start;

    .right-tip {
      margin-left: 15px;
    }
  }

  .preview-content {
    padding: 0 10px;

    .preview-title {
      font-size: 22px;
      margin: 5px 0 0px;
      color: #404040;
      line-height: 34px;
      word-break: break-all;
    }

    .preview-time {
      font-size: 12px;
      color: #aaaaaa;
      line-height: 20px;
    }
  }
}
</style>
<style lang="scss">
.message-info-body {
  .el-message-box__message {
    max-height: 400px;
    overflow: auto;
  }
}
</style>


