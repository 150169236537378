var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "focusManageForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitBefore: _vm.submitBefore,
              footerShow: _vm.footerShow,
            },
            on: { update: _vm.update },
            scopedSlots: _vm._u([
              {
                key: "saveAfterSlot",
                fn: function () {
                  return [
                    _c("v-button", {
                      attrs: { text: "预览" },
                      on: {
                        click: function ($event) {
                          _vm.isPreviewShow = true
                        },
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _vm.isAdd
                ? _c("div", { staticClass: "title-wrapper" }, [
                    _c("span", [
                      _vm._v(
                        "注：焦点图最多显示8幅，排序规则以设置的排序从小到大排序，如排序一致，则最新添加的排前面。"
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c(
                "col2-block",
                {
                  attrs: {
                    title: _vm.isAdd || !_vm.footerShow ? "" : "焦点图信息",
                  },
                },
                [
                  _vm.footerShow
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "标题",
                                prop: "title",
                                rules: [
                                  {
                                    required: true,
                                    message: "标题不允许为空",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-input", {
                                attrs: {
                                  placeholder: "标题字数最多30字",
                                  maxlength: 30,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "title", $$v)
                                  },
                                  expression: "form.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c("el-form-item", { attrs: { label: "标题" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.form.title))]),
                      ]),
                  _vm.footerShow
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "焦点图",
                                prop: "smallPic",
                                rules: [
                                  {
                                    required: true,
                                    message: "请上传图片",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-uploader", {
                                attrs: {
                                  action: _vm.uploadURL,
                                  imgUrls: _vm.form.smallPic,
                                  fileHeight: 294,
                                  fileWidth: 690,
                                  imageWH: [690, 294],
                                  fileSize: 0.5,
                                },
                                on: {
                                  "update:imgUrls": function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "smallPic",
                                      $event
                                    )
                                  },
                                  "update:img-urls": function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "smallPic",
                                      $event
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "tip",
                                      fn: function () {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              "注：仅支持尺寸为690*294px，小于512KB的图片上传"
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1624091469
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c("el-form-item", { attrs: { label: "焦点图" } }, [
                        _c(
                          "div",
                          [
                            _c("v-preview", {
                              attrs: { imgs: _vm.smallPicUrls },
                            }),
                          ],
                          1
                        ),
                      ]),
                  !_vm.isAdd
                    ? _c("el-form-item", { attrs: { label: "发布项目" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.detailItem.communityName)),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.footerShow
                    ? _c(
                        "el-form-item",
                        { attrs: { required: "", label: "跳转类型" } },
                        [
                          _c("checkbox-plus", {
                            attrs: {
                              options: _vm.focusTypeOps,
                              value: _vm.form.focusType,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(_vm.form, "focusType", $event)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _c("el-form-item", { attrs: { label: "跳转类型" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.focusTypeMap[_vm.form.focusType])),
                        ]),
                      ]),
                  _vm.form.focusType === 1
                    ? _c(
                        "div",
                        { staticClass: "el-form-div-wrapper" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "内容",
                                prop: "content",
                                rules: [
                                  {
                                    required: true,
                                    message: "输入内容不能为空",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-ueditor", {
                                ref: "ueditor",
                                model: {
                                  value: _vm.form.content,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "content", $$v)
                                  },
                                  expression: "form.content",
                                },
                              }),
                              _vm.footerShow
                                ? _c(
                                    "div",
                                    { staticClass: "choose-jump-wrapper" },
                                    [
                                      _c("jump-go", {
                                        on: { callback: _vm.jumpCallback },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.focusType === 2 && _vm.footerShow
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "跳转页面" } },
                            [
                              _c("jump-go", {
                                attrs: { btnText: _vm.jumpName, skipType: 2 },
                                on: { callback: _vm.jumpFunCallback },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.focusType === 2 && !_vm.footerShow
                    ? _c("el-form-item", { attrs: { label: "跳转页面" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.jumpName))]),
                      ])
                    : _vm._e(),
                  _vm.footerShow
                    ? _c(
                        "div",
                        [
                          _vm.form.focusType === 3
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "外部链接",
                                    prop: "url",
                                    rules: _vm.urlRules,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "content-wrapper" },
                                    [
                                      _c("v-input", {
                                        attrs: {
                                          placeholder: "外部链接URL地址",
                                          width: _vm.width,
                                          maxlength: 200,
                                        },
                                        model: {
                                          value: _vm.form.url,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "url", $$v)
                                          },
                                          expression: "form.url",
                                        },
                                      }),
                                      _c("span", { staticClass: "right-tip" }, [
                                        _vm._v(
                                          '注：请填写以"http://"或"https://"开头的URL地址\n              '
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.footerShow && _vm.form.focusType === 3
                    ? _c("el-form-item", { attrs: { label: "外部链接" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.form.url))]),
                      ])
                    : _vm._e(),
                  _vm.footerShow
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "发布位置",
                            rules: [
                              {
                                required: true,
                                message: "当前选项不允许为空",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("checkbox-plus", {
                            attrs: {
                              options: _vm.publishTypeOps,
                              value: _vm.form.type,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(_vm.form, "type", $event)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _c("el-form-item", { attrs: { label: "发布位置" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.publishTypeMap[_vm.form.type])),
                        ]),
                      ]),
                  _vm.footerShow && _vm.form.focusType === 1
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "分享文案",
                                prop: "shareContent",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入分享文案",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  placeholder:
                                    "分享时所显示的分享文案，可输入30个字",
                                  maxlength: 30,
                                },
                                model: {
                                  value: _vm.form.shareContent,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "shareContent", $$v)
                                  },
                                  expression: "form.shareContent",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.footerShow && _vm.form.focusType === 1
                    ? _c("el-form-item", { attrs: { label: "分享文案" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.form.shareContent))]),
                      ])
                    : _vm._e(),
                  _vm.footerShow
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "排序",
                                prop: "sort",
                                rules: [
                                  {
                                    required: true,
                                    message: "排序不能为空",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-input-number", {
                                attrs: {
                                  placeholder: "请输入排序",
                                  min: 0,
                                  max: 9999999999,
                                  controls: "",
                                  controlsPosition: "right",
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.sort,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "sort", $$v)
                                  },
                                  expression: "form.sort",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c("el-form-item", { attrs: { label: "排序" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.form.sort))]),
                      ]),
                  _vm.isAdd
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "发布项目",
                                prop: "communityIds",
                                rules: [
                                  {
                                    type: "array",
                                    required: true,
                                    message: "当前选项不允许为空",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c("chosenListPanel", {
                                attrs: { list: _vm.form.communityIds },
                                on: {
                                  "update:list": function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "communityIds",
                                      $event
                                    )
                                  },
                                  select: function ($event) {
                                    _vm.selectCommunityShow = true
                                  },
                                  change: _vm.selectCommunityChange,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.footerShow
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "上线时间",
                                prop: "onlineFromDate",
                                rules: [
                                  {
                                    required: true,
                                    message: "上线时间不允许为空",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "content-wrapper" },
                                [
                                  _c("v-datepicker", {
                                    attrs: {
                                      width: _vm.width,
                                      disabled: !_vm.footerShow,
                                      minuteStep: 10,
                                      minDate: _vm.startMinDate,
                                      type: "datetime",
                                      format: "YYYY-MM-DD HH:mm",
                                    },
                                    model: {
                                      value: _vm.form.onlineFromDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "onlineFromDate",
                                          $$v
                                        )
                                      },
                                      expression: "form.onlineFromDate",
                                    },
                                  }),
                                  _c("span", { staticClass: "right-tip" }, [
                                    _vm._v("注：时间间隔可设为10分钟"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "下线时间",
                                prop: "onlineToDate",
                                rules: [
                                  {
                                    required: true,
                                    message: "下线时间不允许为空",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-datepicker", {
                                attrs: {
                                  width: _vm.width,
                                  disabled: !_vm.footerShow,
                                  minuteStep: 10,
                                  minDate: _vm.endMinDate,
                                  type: "datetime",
                                  format: "YYYY-MM-DD HH:mm",
                                },
                                model: {
                                  value: _vm.form.onlineToDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "onlineToDate", $$v)
                                  },
                                  expression: "form.onlineToDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.footerShow
                    ? _c("el-form-item", { attrs: { label: "上线时间段" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              `${_vm.form.onlineFromDate} ~ ${_vm.form.onlineToDate}`
                            )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.status === 0 || _vm.status === 1
                    ? _c("el-form-item", { attrs: { label: "焦点图状态" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.statusMap[_vm.form.status])),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              !_vm.isAdd && _vm.status !== 0 && _vm.status !== 1
                ? _c(
                    "col2-block",
                    { attrs: { title: "操作信息" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "焦点图状态",
                            rules: [
                              {
                                required: true,
                                message: "请选择状态",
                                trigger: "change",
                              },
                            ],
                            prop: "status",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.auditStatusOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "status", $$v)
                              },
                              expression: "form.status",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.form.status === 3 && !_vm.isBack
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "操作原因",
                                prop: "returnReason",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入操作原因",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-textarea", {
                                attrs: { placeholder: "请输入操作原因" },
                                model: {
                                  value: _vm.form.returnReason,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "returnReason", $$v)
                                  },
                                  expression: "form.returnReason",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isBack
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "被退回原因" } },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.form.returnReason)),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c("multi-select", {
                attrs: {
                  title: "项目列表",
                  isShow: _vm.selectCommunityShow,
                  searchUrl: _vm.getCommunityListURL,
                  headers: _vm.communityTableHeader,
                  searchParams: _vm.searchParams,
                  responseParams: _vm.responseParams,
                  responseKey: _vm.responseKey,
                  backFill: _vm.form.communityIds,
                },
                on: {
                  "update:isShow": function ($event) {
                    _vm.selectCommunityShow = $event
                  },
                  "update:is-show": function ($event) {
                    _vm.selectCommunityShow = $event
                  },
                  "update:backFill": function ($event) {
                    return _vm.$set(_vm.form, "communityIds", $event)
                  },
                  "update:back-fill": function ($event) {
                    return _vm.$set(_vm.form, "communityIds", $event)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "searchSlot",
                    fn: function () {
                      return [
                        _c("v-input", {
                          attrs: { label: "项目名称" },
                          model: {
                            value: _vm.searchParams.communityName,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "communityName", $$v)
                            },
                            expression: "searchParams.communityName",
                          },
                        }),
                        _c("v-select", {
                          attrs: {
                            label: "区域类型",
                            options: _vm.searchRegionTypeOps,
                          },
                          model: {
                            value: _vm.searchParams.searchRegionType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.searchParams,
                                "searchRegionType",
                                $$v
                              )
                            },
                            expression: "searchParams.searchRegionType",
                          },
                        }),
                        _c(
                          "v-select2",
                          _vm._b(
                            {
                              attrs: {
                                label: "所属区域",
                                subjoin: {
                                  regionType: _vm.searchParams.searchRegionType,
                                },
                              },
                              model: {
                                value: _vm.searchParams.regionId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchParams, "regionId", $$v)
                                },
                                expression: "searchParams.regionId",
                              },
                            },
                            "v-select2",
                            _vm.regionParams,
                            false
                          )
                        ),
                        _c("v-input", {
                          attrs: { label: "所在省" },
                          model: {
                            value: _vm.searchParams.province,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "province", $$v)
                            },
                            expression: "searchParams.province",
                          },
                        }),
                        _c("v-input", {
                          attrs: { label: "所在城市" },
                          model: {
                            value: _vm.searchParams.city,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "city", $$v)
                            },
                            expression: "searchParams.city",
                          },
                        }),
                        _c("v-input", {
                          attrs: { label: "所在区" },
                          model: {
                            value: _vm.searchParams.area,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "area", $$v)
                            },
                            expression: "searchParams.area",
                          },
                        }),
                        _c("v-select", {
                          attrs: {
                            label: "项目阶段状态",
                            options: _vm.communityStageOps,
                          },
                          model: {
                            value: _vm.searchParams.communityStage,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "communityStage", $$v)
                            },
                            expression: "searchParams.communityStage",
                          },
                        }),
                        _c("v-select", {
                          attrs: {
                            label: "项目类型",
                            options: _vm.communityTypeOps,
                          },
                          model: {
                            value: _vm.searchParams.communityType,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "communityType", $$v)
                            },
                            expression: "searchParams.communityType",
                          },
                        }),
                        _c("v-select", {
                          attrs: {
                            label: "项目状态",
                            options: _vm.communityStatusOps,
                          },
                          model: {
                            value: _vm.searchParams.communityStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "communityStatus", $$v)
                            },
                            expression: "searchParams.communityStatus",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "mobile-preview",
        {
          attrs: { isShow: _vm.isPreviewShow, title: "内容预览" },
          on: {
            "update:isShow": function ($event) {
              _vm.isPreviewShow = $event
            },
            "update:is-show": function ($event) {
              _vm.isPreviewShow = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "preview-content",
              attrs: { slot: "content" },
              slot: "content",
            },
            [
              _c("div", { staticClass: "preview-title" }, [
                _vm._v(_vm._s(_vm.form.title)),
              ]),
              _c("div", { staticClass: "preview-time" }, [
                _vm._v(_vm._s(_vm.form.onlineFromDate)),
              ]),
              _vm.form.focusType === 1
                ? _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.form.content) },
                  })
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }